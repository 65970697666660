"use client";
import React, { useState, useEffect } from 'react';
import { ArrowUp } from 'lucide-react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      
      const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;
      setScrollProgress(scrollPercentage);

      // Show button when scrolled 20% of the page
      setIsVisible(scrollPosition > windowHeight * 0.2);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!isVisible) return null;

  return (
    <button 
      onClick={scrollToTop}
      className="fixed bottom-4 right-6 w-12 h-12 rounded-full bg-white  items-center justify-center transition-opacity duration-300 hidden md:flex"
      style={{ opacity: isVisible ? 1 : 0 }}
      aria-label="Scroll to top of page"
    >
      <ArrowUp size={24} className="text-gray-600" />
      <svg className="absolute top-0 left-0 w-full h-full -rotate-90" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r="48"
          fill="none"
          stroke="#e0e0e0"
          strokeWidth="4"
        />
        <circle
          cx="50"
          cy="50"
          r="48"
          fill="none"
          stroke="#181AEF"
          strokeWidth="4"
          strokeDasharray="302"
          strokeDashoffset={302 - (scrollProgress / 100) * 302}
        />
      </svg>
    </button>
  );
};

export default ScrollToTopButton;