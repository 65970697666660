import { LucideIcon,Layers, QrCode, Code, Settings, FileText, Eye, Lock, Tag, Palette, Shield, Key, FileCode, SwatchBook, FileSearch, FolderArchive, Image, SprayCan, Monitor, Shuffle, Scissors, Link, LayoutPanelTop, Braces, Blocks, Brackets, Package } from "lucide-react";

interface Tool {
    name: string;
    description: string;
    link: string;
    icon: LucideIcon;
    backgroundColor: string;
    category: string; // New property for category
}

export const toolsList: Tool[] = [
    {
        name: "Color Converter",
        icon: Palette,
        description: "Convert colors between HEX, RGB, RGBA, HSL, HSLA, and CMYK.",
        link: "/tools/color-converter/",
        backgroundColor: "#f0fcff",
        category: "Color Tools"
    },
    {
        name: "Tailwind CSS Color Generator",
        icon: SwatchBook,
        description: "Generate Tailwind CSS colors with a live preview.",
        link: "/tools/tailwind-color-generator/",
        backgroundColor: "#e6f7ff",
        category: "Tailwind Tools"
    },
    {
        name: "Meta Tag Analyzer",
        icon: Tag,
        description: "Analyze and optimize the meta tags of your webpage.",
        link: "/tools/meta-analyzer/",
        backgroundColor: "#f0f4ff",
        category: "SEO Tools"
    },
    {
        name: "Bootstrap CSS to Tailwind CSS",
        icon: Code,
        description: "Convert Bootstrap code to Tailwind effortlessly.",
        link: "/tools/bootstrap-to-tailwind/",
        backgroundColor: "#f5f9ff",
        category: "Tailwind Tools"
    },
    {
        name: "HTML to Liquid",
        icon: FileCode,
        description: "Convert HTML into Shopify Liquid templates.",
        link: "/html-to-liquid/",
        backgroundColor: "#fef9e7",
        category: "Code Conversion"
    },
    {
        name: "HTML/CSS Beautifier",
        icon: Code,
        description: "Beautify your HTML and CSS files instantly.",
        link: "/tools/beautify/",
        backgroundColor: "#f7fff7",
        category: "Code Formatting"
    },
    {
        name: "Image to SVG Converter",
        icon: Image,
        description: "Convert PNG and JPEG images to SVG instantly.",
        link: "https://www.infyways.com/tools/image-to-svg/",
        backgroundColor: "#f0ffe6",
        category: "Image Tools"
    },
    {
        name: "JSON/XML Converter",
        icon: Settings,
        description: "Convert JSON to XML and vice versa.",
        link: "/tools/json-to-xml/",
        backgroundColor: "#fffbf0",
        category: "Data Conversion"
    },
    {
        name: "Favicon Generator",
        icon: FileCode,
        description: "Generate favicons for your website.",
        link: "/tools/favicon-generator/",
        backgroundColor: "#fef0ff",
        category: "Image Tools"
    },
    {
        name: "Readability Checker",
        icon: Eye,
        description: "Analyze the readability of text or webpages.",
        link: "/tools/readability/",
        backgroundColor: "#f0f0ff",
        category: "Content Tools"
    },
    {
        name: "Lorem Ipsum Generator",
        icon: FileText,
        description: "Generate placeholder text for your projects.",
        link: "/tools/lorem-ipsum-generator/",
        backgroundColor: "#f0fff4",
        category: "Content Tools"
    },
    {
        name: "Password Generator",
        icon: Lock,
        description: "Generate strong passwords and check their strength.",
        link: "/tools/password-generator/",
        backgroundColor: "#f9f0ff",
        category: "Security Tools"
    },
    {
        name: "Base64 Converter",
        icon: Shield,
        description: "Encode and decode text with Base64 encoding.",
        link: "/tools/base64-converter/",
        backgroundColor: "#e8f0fe",
        category: "Data Conversion"
    },
    {
        name: "MD5 Generator",
        icon: Key,
        description: "Generate MD5 hashes from text.",
        link: "/tools/md5-generator/",
        backgroundColor: "#f5e8ff",
        category: "Security Tools"
    },
    {
        name: ".htaccess Generator",
        icon: FileCode,
        description: "Generate .htaccess configurations easily.",
        link: "/tools/htaccess-generator/",
        backgroundColor: "#f2f2f0",
        category: "Web Development"
    },
    {
        name: "Nginx Configuration Generator",
        icon: FileCode,
        description: "Generate Nginx configurations easily.",
        link: "/tools/nginx-generator/",
        backgroundColor: "#f2f2f0",
        category: "Web Development"
    },    
    {
        name: "Markdown Editor",
        icon: FileText,
        description: "Edit Markdown with live preview and export options.",
        link: "/tools/markdown-editor/",
        backgroundColor: "#f0faff",
        category: "Content Tools"
    },
    {
        name: "HTML to Markdown Converter",
        icon: Code,
        description: "Convert HTML to Markdown and vice versa.",
        link: "/tools/html-markdown-converter/",
        backgroundColor: "#e6ffe6",
        category: "Code Conversion"
    },
    {
        name: "XML Validator",
        icon: FileSearch,
        description: "Validate and format your XML files easily.",
        link: "/tools/xml-validator/",
        backgroundColor: "#f0f5ff",
        category: "Code Formatting"
    },
    {
        name: "SVG Optimizer",
        icon: FolderArchive,
        description: "Optimize and format your SVG files.",
        link: "/tools/svg-optimizer/",
        backgroundColor: "#fff0f0",
        category: "Image Tools"
    },
    {
        name: "SVG Converter",
        icon: Image,
        description: "Convert SVG images to PNG, JPEG, WEBP, PDF, and Base64.",
        link: "/tools/svg-converter/",
        backgroundColor: "#f0ffe6",
        category: "Image Tools"
    },
    {
        name: "Image to WebP Converter",
        icon: Image,
        description: "Convert PNG and JPEG images to WebP with adjustable quality and live preview.",
        link: "/tools/image-to-webp/",
        backgroundColor: "#e6faff",
        category: "Image Tools"
    },
    {
        name: "Meta Data Viewer",
        icon: FileSearch,
        description: "View detailed metadata of your images including EXIF data.",
        link: "/tools/metadata-viewer/",
        backgroundColor: "#f0f4f7",
        category: "Image Tools"
    },
    {
        name: "Tailwind CSS Gradient Generator",
        icon: SprayCan,
        description: "Generate gradients with Tailwind CSS and download as SVG.",
        link: "/tools/tailwind-gradient-generator/",
        backgroundColor: "#f0ffe6",
        category: "Tailwind Tools"
    },
    {
        name: "Website Responsive Checker",
        icon: Monitor,
        description: "Check how your website responds on 50+ devices including phones, tablets, and desktops.",
        link: "/tools/website-responsive-checker/",
        backgroundColor: "#f0ffe6",
        category: "Web Development"
    },
    {
        name: "Color Palette Extractor",
        icon: Image,
        description: "Extract color palettes from any image.",
        link: "/tools/color-palette-image/",
        backgroundColor: "#ffe6f0",
        category: "Color Tools"
    },
    {
        name: "Color Palette Generator",
        icon: Palette,
        description: "Generate stunning color palettes for your design projects.",
        link: "/tools/color-palette-generator/",
        backgroundColor: "#f0fce6",
        category: "Color Tools"
    },
    {
        name: "Schema Generator",
        icon: FileCode,
        description: "Generate structured data schemas for SEO.",
        link: "/tools/schema-generator/",
        backgroundColor: "#e6f0ff",
        category: "SEO Tools"
    },
    {
        name: "HTML to JSX Converter",
        icon: Code,
        description: "Convert HTML to React JSX code effortlessly.",
        link: "/tools/html-to-jsx/",
        backgroundColor: "#f0f8ff",
        category: "Code Conversion"
    },
    {
        name: "CSS to Tailwind Converter",
        icon: Code,
        description: "Convert standard CSS to Tailwind CSS classes.",
        link: "/tools/css-to-tailwind/",
        backgroundColor: "#e6f7ff",
        category: "Tailwind Tools"
    },
    {
        name: "QR Code Generator",
        icon: QrCode,
        description: "Generate custom QR codes for any use and download them.",
        link: "/tools/qr-generator/",
        backgroundColor: "#f0faff",
        category: "Utility Tools"
    },
    {
        name: "Random Number Generator",
        icon: Shuffle,
        description: "Generate random numbers within a customizable range with real-time updates.",
        link: "/tools/random-number-generator/",
        backgroundColor: "#fff5f0",
        category: "Utility Tools"
    },
    {
        name: "Unused CSS Cleaner Tool",
        icon: Scissors,
        description: "Automatically clean and optimize unused CSS from your website for better performance.",
        link: "/tools/unused-css/",
        backgroundColor: "#f0fff5",
        category: "CSS Tools"
    },
    {
        name: "Canonical Checker",
        icon: FileCode,
        description: "Check if your URL is canonical or not.",
        link: "/tools/canonical-checker/",
        backgroundColor: "#f0f5ff",
        category: "SEO Tools"
    },
    {
        name: "Broken Link Checker",
        icon: Link,
        description: "Check for broken links in your website.",
        link: "/tools/broken-link-checker/",
        backgroundColor: "#fff0f0",
        category: "SEO Tools"
    },

    {
        name: "Tailwind CSS Grid Generator",
        icon: LayoutPanelTop,
        description: "Generate responsive grid layouts effortlessly using Tailwind CSS.",
        link: "/tools/tailwind-grid-generator/",
        backgroundColor: "#fff0f0",
        category: "Tailwind Tools"
    },
    {
        name: "Wordpress Plugin Generator",
        icon: Braces,
        description: "Quickly generate WordPress plugins using a simple template.",
        link: "/tools/wordpress-plugin-generator/",
        backgroundColor: "#fff0f0",
        category: "WordPress Tools"
    },
    {
        name: "Joomla Plugin Generator",
        icon: Blocks,
        description: "Quickly generate Joomla plugins using a simple template.",
        link: "/tools/joomla-plugin-generator/",
        backgroundColor: "#fff0f0",
        category: "Joomla Tools"
    },
    {
        name: "Joomla Module Generator",
        icon: Package,
        description: "Easily develop Joomla modules.",
        link: "/tools/joomla-module-generator/",
        backgroundColor: "#fff0f0",
        category: "Joomla Tools"
    },
    {
        name: "Image Resizer",
        icon: Image,
        description: "Resize images with customizable dimensions and quality settings.",
        link: "/tools/image-resizer/",
        backgroundColor: "#f0fff4",
        category: "Image Tools"
    },
    {
        name: "Joomla 5 Template Generator",
        icon: Layers,
        description: "Create custom Joomla 5 templates effortlessly.",
        link: "/tools/joomla-template-generator/",
        backgroundColor: "#f4f0ff",
        category: "Joomla Tools"
    }
];